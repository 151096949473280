import React from 'react';
import PropTypes from 'prop-types';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import './style.scss';

function WorkingHours({ primaryColor, secondaryColor, color }) {
  return (
    <div className="component-working-hours">
      <WatchLaterIcon sx={{ color: primaryColor, fontSize: 40, marginRight: '.5rem' }} />
      <div className="column">
        <div>
          <span style={{ color }}>Mon-Fri:</span>
          <span style={{ color: primaryColor }} className="bold">8:30 AM - 5:00 PM</span>
        </div>
        <span style={{ color: secondaryColor }}>Sat-Sun: Closed</span>
      </div>
    </div>
  );
}

WorkingHours.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  color: PropTypes.string,
};

WorkingHours.defaultProps = {
  primaryColor: '#4766BA',
  secondaryColor: '#A5A5A5',
  color: '#404040',
};

export default WorkingHours;
